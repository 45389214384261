import React from 'react'

export class Header extends React.Component {
    render() {
        return(
            <header className="container-fluid text-center App-header py-4 fixed-top">
                <b>darius sherman</b>
            </header>
        );
    }
}